import { CHANGE_LANGUAGE, CHANGE_LANGUAGUE, CHANGE_NOTIFICATIONS, CHANGE_TOKEN, CHANGE_USER_DATA } from "./GlobalActionsTypes"

const initialState = {
    lang:'en',
    notifications:{},
    token:localStorage.getItem('token')?localStorage.getItem('token'):null,
    userData:localStorage.getItem('userData')?JSON.parse(localStorage.getItem('userData') as string):null,
}

const GlobalReducer = (state = initialState ,action:any)=>{
    switch (action.type){
        case CHANGE_LANGUAGE :{
            return {
                ...state,
                lang:action.lang
            }
        }
        case CHANGE_TOKEN :{
            return {
                ...state,
                token:action.token
            }
        }
        case CHANGE_LANGUAGUE :{
            return {
                ...state,
                lang:action.code
            }
        }
        case CHANGE_USER_DATA :{
            return {
                ...state,
                userData:action.userData
            }
        }
        case CHANGE_NOTIFICATIONS :{
            return {
                ...state,
                notifications:action.notifications
            }
        }
        default: return state;
    }
}

export default GlobalReducer